import React from "react";
import { Formik, Field, Form, FieldArray } from "formik";
import { Mutation } from "@apollo/react-components";
import { MutationFunction } from "@apollo/react-common";
import * as S from "./styles";
import * as SVG from "./svgs";
import { useEditToggle } from "./hooks";
import { Company, Contact, Location } from "./apiTypes";
import {
  StyledPanelFormWrapper,
  RepeatableFieldButton,
  RadioButton,
  EditableSelect,
  SelectOptions,
} from "./StyledForm";
import { associationMutations, primaryAssociation } from "./util";
import {
  updateUrlMutation,
  createUrlMutation,
  deleteUrlMutation,
} from "./mutations/url";

export default function Urls({
  subject,
  selectOptions,
}: {
  // subject: Company | Contact | Location;
  subject: Company | Contact;
  selectOptions: SelectOptions[];
}) {
  const { isOpen, open, close } = useEditToggle();
  const preferredIdx: number = (subject.urls || []).findIndex(
    (p) => p.preferred
  );
  const preferredIdxValue: string | null =
    preferredIdx >= 0 ? preferredIdx.toString() : null;

  return (
    <S.Panel pb={2}>
      <S.UnderlinedHeader>
        <S.Flex alignItems="flex-end" justifyContent="space-between">
          <S.Flex alignItems="center" justifyContent="flex-start">
            <SVG.GlobeSvg />
            Websites
          </S.Flex>
          {!isOpen ? (
            <S.Button size="small" onClick={open}>
              Edit
            </S.Button>
          ) : (
            <S.Button size="small" onClick={close}>
              Cancel
            </S.Button>
          )}
        </S.Flex>
      </S.UnderlinedHeader>
      {!isOpen ? (
        <S.Box>
          <S.ContactList>
            {subject.urls && subject.urls.length > 0
              ? subject.urls.map((url) => (
                  <li key={url.id}>
                    {url.preferred ? "*" : ""}
                    {url.label}:{" "}
                    <b>
                      <S.A
                        href={url.url}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {url.url}
                      </S.A>
                    </b>
                  </li>
                ))
              : "No Websites on Record"}
          </S.ContactList>
        </S.Box>
      ) : (
        <Mutation<MutationFunction>
          mutation={createUrlMutation}
          refetchQueries={["companyQuery", "ContactQuery"]}
        >
          {(createUrl, { loading }) => (
            <Mutation<MutationFunction>
              mutation={updateUrlMutation}
              refetchQueries={["companyQuery", "ContactQuery"]}
            >
              {(updateUrl, { loading }) => (
                <Mutation<MutationFunction>
                  mutation={deleteUrlMutation}
                  refetchQueries={["companyQuery", "ContactQuery"]}
                >
                  {(deleteUrl, { loading }) => {
                    return (
                      <Formik
                        initialValues={{
                          urls: subject.urls || [],
                          preferredIdx: preferredIdxValue,
                        }}
                        onSubmit={async (values) => {
                          let input: any = { ...values };
                          if (values.preferredIdx) {
                            input.urls[
                              parseInt(values.preferredIdx)
                            ].preferred = true;
                          }
                          await associationMutations(
                            input.urls,
                            subject.urls ? subject.urls : [],
                            {
                              create: (a) =>
                                createUrl({
                                  variables: {
                                    input: {
                                      label: a.label,
                                      url: a.url,
                                      subjectId: subject.id,
                                      preferred: a.preferred,
                                    },
                                  },
                                }),
                              update: ({ id, ...a }) =>
                                updateUrl({
                                  variables: {
                                    input: {
                                      label: a.label,
                                      url: a.url,
                                      urlId: id,
                                      preferred: a.preferred,
                                    },
                                  },
                                }),
                              destroy: ({ id }) =>
                                deleteUrl({
                                  variables: { input: { urlId: id } },
                                }),
                            }
                          );
                          close();
                        }}
                        render={({ values }) => (
                          <Form>
                            <StyledPanelFormWrapper>
                              <FieldArray
                                name="urls"
                                render={(arrayHelpers) => (
                                  <div>
                                    {values.urls &&
                                    values.urls.filter(
                                      (a) => a.label !== "delete"
                                    ).length > 0 ? (
                                      values.urls.map((url, index) => (
                                        <S.Flex
                                          mb={1}
                                          alignItems="center"
                                          key={index}
                                        >
                                          <div
                                            style={{
                                              flexBasis: "5%",
                                              marginRight: 5,
                                            }}
                                          >
                                            <Field
                                              name="preferredIdx"
                                              id={`urls.${index}.preferred`}
                                              index={index}
                                              component={RadioButton}
                                              label="Preferred Website"
                                            />
                                          </div>
                                          <div
                                            style={{
                                              flexBasis: "30%",
                                              marginRight: 5,
                                            }}
                                          >
                                            <Field
                                              required
                                              id={`urls.${index}.label`}
                                              name={`urls.${index}.label`}
                                              component={EditableSelect}
                                              className="label-container"
                                              classNamePrefix="label"
                                              closeMenuOnSelect={true}
                                              options={selectOptions}
                                            />
                                          </div>
                                          <div style={{ flexBasis: "60%" }}>
                                            <Field
                                              required
                                              id={`urls.${index}.url`}
                                              name={`urls.${index}.url`}
                                              placeholder="sample.com"
                                              type="url"
                                              className="tagged-field"
                                            />
                                          </div>
                                          <RepeatableFieldButton
                                            type="button"
                                            onClick={() =>
                                              arrayHelpers.remove(index)
                                            } // remove a friend from the list
                                          >
                                            <SVG.MinusSvg />
                                          </RepeatableFieldButton>
                                          <RepeatableFieldButton
                                            type="button"
                                            onClick={() =>
                                              arrayHelpers.insert(index + 1, "")
                                            } // insert an empty string at a position
                                          >
                                            <SVG.PlusSvg />
                                          </RepeatableFieldButton>
                                        </S.Flex>
                                      ))
                                    ) : (
                                      <button
                                        type="button"
                                        onClick={() => arrayHelpers.push("")}
                                      >
                                        Add a url.
                                      </button>
                                    )}
                                  </div>
                                )}
                              />

                              <button type="submit" disabled={loading}>
                                Sav{loading ? "ing" : "e"}
                              </button>
                            </StyledPanelFormWrapper>
                          </Form>
                        )}
                      />
                    );
                  }}
                </Mutation>
              )}
            </Mutation>
          )}
        </Mutation>
      )}
    </S.Panel>
  );
}

export function UrlList({
  subject,
  label,
  labelled,
  primary,
}: {
  subject: Company | Contact | Location;
  label?: string;
  labelled?: boolean;
  primary?: boolean;
}) {
  if (!subject.urls)
    return <S.ContactList>No Websites on Record</S.ContactList>;
  if (subject.urls.length < 1)
    return <S.ContactList>No Websites on Record</S.ContactList>;

  let websites;

  const primaries = subject.urls.filter((url) => url.preferred);
  const sites = label
    ? subject.urls.filter((url) => url.label === label)
    : subject.urls;

  if (label) {
    if (!sites) websites = primaries;
    if (sites.length < 1) websites = primaries;
  } else if (primary) websites = primaries;
  else websites = sites;

  if (!websites) return <S.ContactList>No Websites on Record</S.ContactList>;
  if (websites.length < 1)
    return <S.ContactList>No Websites on Record</S.ContactList>;

  return (
    <S.ContactList>
      {websites.map((url) => (
        <li key={url.id}>
          {url.label}:{" "}
          <b>
            <S.A href={url.url} target="_blank" rel="noopener noreferrer">
              {url.url}
            </S.A>
          </b>
        </li>
      ))}
    </S.ContactList>
  );
}

export function PrimaryUrl({
  subject,
}: {
  subject: Company | Contact | Location;
}) {
  const primary = primaryAssociation(subject.urls);
  return (
    <>
      {primary ? (
        <S.A
          href={`${
            primary.url.includes("http") ? primary.url : `http://${primary.url}`
          }`}
        >
          <S.Flex ml={0} my={1} py={1} alignItems="center">
            <SVG.GlobeSvg />

            {primary.url}
          </S.Flex>
        </S.A>
      ) : (
        <></>
      )}
    </>
  );
}
